<style lang="less" scoped>
  @import "../../assets/css/variables";
  .page-container {
    font-size: 14px;
    color: #666666;
    > div {
      clear: both;
      margin-bottom: 30px;
      > span:nth-of-type(1) {
        margin-right: 30px;
      }
    }
    .fb-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      .lt {
        flex-basis: 100px;
      }
    }

  }


</style>

<template :is="view"
          transition="fade"
          transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/feedback' }">用户反馈</el-breadcrumb-item>
      <el-breadcrumb-item>反馈详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <div>
          <span>反馈账号</span>
          <span>{{fdbackInfo.userName}}</span>
      </div>
      <div>
          <span>联系方式</span>
          <span>{{fdbackInfo.mobile}}</span>
      </div>
      <div>
          <span>反馈时间</span>
          <span>{{formatTime1(fdbackInfo.createTime)}}</span>
      </div>
      <div class="fb-content">
        <div class="lt">
          反馈内容
        </div>
        <div class="rt">
          {{fdbackInfo.remarks}}
        </div>
      </div>
    </div>
  </div>  

</template>

<script>

  import {getFeedbackById} from '../../services/user';
  import {Notification} from 'element-ui';
  import {PAGE_SIZE} from '../../config';
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment';

  export default{
    data(){
      return {
        fdbackInfo: {},
      }
    },
    components: {
    },
    created() {
      const id = this.$route.params.id;
      this.getInfo(id);
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
       /**
        *  时间格式化
        */
      formatTime1(item){
        return item ? moment(item).format('YYYY-MM-DD HH:mm:ss') : '';
      },

      /**
       * 获取客户信息；
       */
      async getInfo(id) {
        const res = await getFeedbackById(id);
        if (res && res.errorCode === 0 && res.data) {
          this.fdbackInfo = res.data;
        }  
      }
    }
    
  }

</script>
